import Axios, { AxiosResponse } from 'axios';

interface CommonImageDTO {
    id: string;
    description: string;
    image: string;
}

export interface CommonImages {
    logo: string;
    environment: string;
    electrics: string;
}

export function loadCommonImagesService(): Promise<CommonImages> {
    return Axios.post('api/pages.commonContent.loadImages.php')
        .then((response: AxiosResponse<Array<CommonImageDTO>>) => {
            const commonImages: CommonImages = {
                logo: response.data.filter(f => f.description === 'logo')[0]?.image,
                electrics: response.data.filter(f => f.description === 'electrics')[0]?.image,
                environment: response.data.filter(f => f.description === 'environment')[0]?.image
            };
            return commonImages;
        }).catch(() => ({
            logo: 'error',
            electrics: 'error',
            environment: 'error'
        }));
}