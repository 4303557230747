import Axios, { AxiosResponse } from 'axios';

export enum UserType {
    LOGGED_USER = 1,
    OTHER = 2
}

export function getUserIdService(): Promise<UserType> {
    return Axios.post('api/auth.getUserId.php')
        .then((response: AxiosResponse) => response.data as UserType)
        .catch(() => UserType.OTHER)
}