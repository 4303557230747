import * as React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HomePage } from './pages/home/HomePage';
import { Footer } from './components/footer/Footer';
import { NavigationBar } from './components/navigation-bar/NavigationBar';
import { ContentPage } from './pages/common-content/CommonContentPage';
import { ProductsPage } from './pages/products-page/ProductsPage';
import { RouteName, AuthRouteName } from './route';
import { loadItemsService, PowersysCategory } from './pages/products-page/services/loadItems.service';
import { LoadingIndicator } from './components/loading-indicator';
import { loadCommonImagesService, CommonImages } from './pages/common-content/services';
import { SliderItem } from './components/slider/types';
import { loadSliderItemsService } from './components/slider/services/loadSliderItems';
import { getUserIdService, UserType } from './auth/services';
import { CookiesPolicy } from './components/cookies-policy/CookiesPolicy';
import { AboutPageContent } from './pages/about/AboutPageContent';
import { ContactPageContent } from './pages/contact/ContactPageContent';
import { ManualPageContent } from './pages/manual/ManualPageContent';
import { EditSliderPage } from './pages/admin/edit-slider/EditSliderPage';
import { EditCategoryPage } from './pages/admin/edit-category/EditCategoryPage';
import { EditSubCategoryPage } from './pages/admin/edit-sub-category/EditSubCategoryPage';
import { EditItemPage } from './pages/admin/edit-item/EditItemPage';

interface AppState {
    itemsTree: Array<PowersysCategory>;
    commonImages: CommonImages;
    sliderItems: Array<SliderItem>;
    user: UserType;
    isLoaded: boolean;
}

export class App extends React.Component<{}, AppState> {

    constructor(props: any) {
        super(props);

        this.state = {
            itemsTree: [],
            commonImages: {
                electrics: '',
                environment: '',
                logo: ''
            },
            sliderItems: [],
            user: UserType.OTHER,
            isLoaded: false
        }
    }

    componentDidMount() {
        const asyncServices: Array<Promise<Array<PowersysCategory> | CommonImages | Array<SliderItem> | UserType>> = [];
        asyncServices.push(loadItemsService());
        asyncServices.push(loadCommonImagesService());
        asyncServices.push(loadSliderItemsService());
        asyncServices.push(getUserIdService());

        Promise.all(asyncServices).then((response: Array<any>) => {
            const items: Array<PowersysCategory> = response[0];
            const images: CommonImages = response[1];
            const sliderItems: Array<SliderItem> = response[2];
            const userType: UserType = response[3];

            this.setState({
                itemsTree: items,
                commonImages: images,
                sliderItems,
                user: userType,
                isLoaded: true
            });
        })
    }

    render() {
        if (!this.state.isLoaded) {
            return <LoadingIndicator />
        }

        const authorised: boolean = this.state.user === UserType.LOGGED_USER;

        return (
            <Router>
                <NavigationBar logoImage={this.state.commonImages.logo} itemsTree={this.state.itemsTree} />
                <div className='global-dummy-nav' />
                <div className='global-page-container'>
                    <Switch>
                        <Route exact path={RouteName.Home}>
                            <HomePage sliderItems={this.state.sliderItems} />
                        </Route>
                        <Route path={RouteName.Products}>
                            <ProductsPage itemsTree={this.state.itemsTree} />
                        </Route>
                        <Route path={RouteName.Manual}>
                            <ContentPage children={<ManualPageContent />} commonImages={this.state.commonImages} />
                        </Route>
                        <Route path={RouteName.Contact}>
                            <ContentPage children={<ContactPageContent />} commonImages={this.state.commonImages} />
                        </Route>
                        <Route path={RouteName.About}>
                            <ContentPage children={<AboutPageContent />} commonImages={this.state.commonImages} />
                        </Route>
                        <Route path={AuthRouteName.EditSlider}>
                            <EditSliderPage authorised={authorised} />
                        </Route>
                        <Route path={AuthRouteName.EditCategory}>
                            <EditCategoryPage authorised={authorised} />
                        </Route>
                        <Route path={AuthRouteName.EditSubCategory}>
                            <EditSubCategoryPage authorised={authorised} />
                        </Route>
                        <Route path={AuthRouteName.EditItem}>
                            <EditItemPage authorised={authorised} />
                        </Route>
                        <Route path='*'>
                            <ContentPage children={<div style={{ margin: '20px', textAlign: 'center' }}>Nie znaleziono strony!</div>}
                                commonImages={this.state.commonImages} />
                        </Route>
                    </Switch>
                </div>
                <Footer userType={this.state.user} />
                <CookiesPolicy />
            </Router>
        );
    }
}

export default App;