import Axios, { AxiosResponse } from 'axios';

export interface PowersysCategory {
    id: string;
    name: string;
    groups: Array<PowersysGroup>;
}


export interface PowersysGroup {
    id: string;
    description: string;
    content: string;
    items: Array<PowersysItem>;
}

export interface PowersysItem {
    id: string;
    name: string;
    thumbnail: string;
    imgBase64: string;
    pdfBase64: string;
}

export function loadItemsService(): Promise<Array<PowersysCategory>> {
    return Axios.post('api/pages.products.loadItems.php')
        .then((response: AxiosResponse<Array<PowersysCategory>>) => {
            return response.data;
        }).catch(() => []);
}