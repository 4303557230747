import Axios, { AxiosResponse } from 'axios';
import { SubCategory } from '../types';
import { encodeSubCategory } from './utils';

export function addSubCategoriesService(subCategory: SubCategory): Promise<AxiosResponse> {
    subCategory = encodeSubCategory(subCategory);
    const params: string = `itemCategoryId=${subCategory.itemCategoryId}&description=${subCategory.description}&content=${subCategory.content}`;

    return Axios.post('api/pages.admin.subCategory.add.php', params)
        .then((response: AxiosResponse) => response.data)
        .catch((error) => alert(error));
}