import Axios, { AxiosResponse } from 'axios';
import { toBoolean } from '../../../../utils';
import { Item } from '../types';

interface ItemDto {
    id: string;
    itemGroupId: string;
    name: string;
    thumbnail: string;
    hasImage: boolean,
    hasPdf: boolean
}

export function loadItemsService(): Promise<Array<Item>> {
    return Axios.post('api/pages.admin.item.load.php')
        .then((response: AxiosResponse<Array<ItemDto>>) => resolveItems(response.data))
        .catch(() => []);
}

function resolveItems(rawItems: Array<ItemDto>): Array<Item> {
    return rawItems.map(raw => ({
        id: raw.id,
        itemGroupId: raw.itemGroupId,
        name: raw.name,
        thumbnail: raw.thumbnail,
        hasImage: toBoolean(raw.hasImage),
        hasPdf: toBoolean(raw.hasPdf)
    }));
}