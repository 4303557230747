import * as React from 'react';
import { PdfViewer } from '../pdf-viewer/PdfViewer';
const PS_PDF_INPUT: 'ps-pdf-input' = 'ps-pdf-input';

interface PDFInputProps {
    defaultPdf?: string;
    maxSizeKB?: number;
}

interface PDFInputState {
    pdfSource: string;
}

const KILO: number = 1024;
const DEFAULT_MAX_PDF_SIZE_BYTES = 512 * KILO;

export class PDFInput extends React.Component<PDFInputProps, PDFInputState> {

    private acceptTypes: string = 'application/pdf';
    private readonly maxSizeBytes: number;

    constructor(props: PDFInputProps) {
        super(props);
        this.state = {
            pdfSource: this.props.defaultPdf || ''
        }

        if (props.maxSizeKB) {
            this.maxSizeBytes = props.maxSizeKB * KILO;
        } else {
            this.maxSizeBytes = DEFAULT_MAX_PDF_SIZE_BYTES;
        }

        this.validatePDF = this.validatePDF.bind(this);

    }

    render() {
        return (
            <PS_PDF_INPUT>
                <div className={PS_PDF_INPUT}>
                    PDF: <input type='file' accept={this.acceptTypes} onChange={(event: React.ChangeEvent) => this.validatePDF(event)} />
                    {this.state.pdfSource && <PdfViewer src={this.state.pdfSource || ''} />}
                </div>
            </PS_PDF_INPUT>
        )
    }

    public getPDFString(): string {
        return this.state.pdfSource;
    };

    private validatePDF(event: React.ChangeEvent) {
        const input: HTMLInputElement = event.currentTarget as HTMLInputElement;
        let isPDFValid: boolean = true;
        let errorMessage: string = '';
        if (input && input.files !== null) {
            const file: File = input.files[0];
            if (file.size > this.maxSizeBytes) {
                errorMessage += `Przekroczono dozwolony rozmiar pliku!\nMaksymalny rozmiar: ${this.maxSizeBytes} bajtów.\nRozmiar obrazka: ${file.size} bajtów.\n`;
                isPDFValid = false;
            }

            if (file.type !== this.acceptTypes) {
                errorMessage += `Niepoprawny typ pliku!\nOczekiwany typ: ${this.acceptTypes}\n`;
                isPDFValid = false;
            }

            if (isPDFValid) {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file);

                fileReader.onload = (evnt) => {
                    this.setState({ pdfSource: fileReader.result as string });
                };
            }

            if (!isPDFValid) {
                input.value = '';
                alert(errorMessage);
            }
        }
    }
}


declare global {
    namespace JSX {
        interface IntrinsicElements {
            [PS_PDF_INPUT]: any
        }
    }
}