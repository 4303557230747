import Axios, { AxiosResponse } from 'axios';
import { SliderItem } from '../types';;


export function loadSliderItemsService(): Promise<Array<SliderItem>> {
    return Axios.post('api/components.slider.loadItems.php')
        .then((response: AxiosResponse<Array<SliderItem>>) => {
            return response.data;
        }, () => {
            return [];
        })
}