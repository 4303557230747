import * as React from 'react';

const PS_LOADING_INDICATOR: 'ps-loading-indicator' = 'ps-loading-indicator';

export class LoadingIndicator extends React.Component<{}, {}> {
    render() {
        return (
            <ps-loading-indicator>
                <div className={PS_LOADING_INDICATOR}>
                    <div className={`${PS_LOADING_INDICATOR}__pulse`}>
                        PowerSys
                    </div>
                </div>
            </ps-loading-indicator>
        )
    }
}


declare global {
    namespace JSX {
        interface IntrinsicElements {
            [PS_LOADING_INDICATOR]: any
        }
    }
}

export default LoadingIndicator;