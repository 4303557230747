import * as React from 'react';
import {PowersysItem} from '../services/loadItems.service';
import {isEmpty} from '../../../utils';
import {createItemId} from '../utils';

const PS_PRODUCT_BOX: 'ps-product-box' = 'ps-product-box';

export interface ProductBoxProps {
    items: Array<PowersysItem>;
}

export class ProductBox extends React.Component<ProductBoxProps, {}> {
    constructor(props: ProductBoxProps) {
        super(props);
        this.state = {};
        this.onImageClick = this.onImageClick.bind(this);
    }

    render() {
        if (isEmpty(this.props.items)) {
            return <PS_PRODUCT_BOX />;
        }

        return (
            <PS_PRODUCT_BOX>
                <div className={PS_PRODUCT_BOX}>{this.props.items?.map((item) => this.renderItem(item))}</div>
            </PS_PRODUCT_BOX>
        );
    }

    private renderItem(item: PowersysItem): JSX.Element {
        return (
            <div key={item.id} className="item-box">
                <div className="item-box-name">{item.name}</div>
                <div
                    onClick={() => this.onImageClick(item)}
                    className="item-box-image"
                    style={{backgroundImage: `url(${item.thumbnail})`}}
                />
            </div>
        );
    }

    private onImageClick(item: PowersysItem): void {
        // todo
        const element: HTMLElement = document.querySelector('#' + createItemId(item)) as HTMLElement;
        element?.click();
    }
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            [PS_PRODUCT_BOX]: any;
        }
    }
}
