import * as React from 'react';
import 'pdfjs-dist/webpack';
import { PDFDocumentProxy, getDocument, PDFPageProxy, PDFPageViewport, PDFRenderTask } from 'pdfjs-dist';
import { CommonIconButton } from '../buttons/CommonButton';
import { IconClass } from '../../icons';

const PS_PDF_VIEWER: 'ps-pdf-viewer' = 'ps-pdf-viewer';

interface PdfViewerProps {
    src: string;
}

interface PdfViewerState {
    pdfInstance: PDFDocumentProxy;
    totalPageCount: number;
    currentPageNumber: number;
}

export class PdfViewer extends React.Component<PdfViewerProps, PdfViewerState> {

    private canvasContainerElement: HTMLDivElement | undefined;
    private canvasElement: HTMLCanvasElement | undefined;
    private isPageRendering: boolean = false;
    private pageNumberPending: number | null = null;


    constructor(props: PdfViewerProps) {
        super(props);

        this.onPreviousButtonClick = this.onPreviousButtonClick.bind(this);
        this.onNextButtonClick = this.onNextButtonClick.bind(this);

        this.state = {
            pdfInstance: null as any,
            currentPageNumber: 1,
            totalPageCount: 1
        }
    }

    componentDidMount() {
        this.getDocument();
    }

    componentDidUpdate(prevProps: PdfViewerProps) {
        if (prevProps.src !== this.props.src) {
            this.getDocument();
        }
    }

    private getDocument(): void {
        getDocument(this.props.src).promise
            .then((pdf: PDFDocumentProxy) => {
                this.setState({
                    pdfInstance: pdf,
                    totalPageCount: pdf.numPages
                }, () => this.renderPage(this.state.currentPageNumber));
            }, () => alert('Niepoprawny format dokumentu!'));
    }

    render() {
        const canvasContainerClassName: string = this.state.pdfInstance ? `${PS_PDF_VIEWER}-canvas-container` : `${PS_PDF_VIEWER}-canvas-container-hidden`;

        return (
            <PS_PDF_VIEWER>
                <div className={PS_PDF_VIEWER}>
                    <div className={`${PS_PDF_VIEWER}-toolbar`}>
                        <CommonIconButton icon={IconClass.ChevronLeft} onClick={this.onPreviousButtonClick} />
                        <span className={`${PS_PDF_VIEWER}-toolbar-pages`}>{this.state.currentPageNumber}/{this.state.totalPageCount}</span>
                        <CommonIconButton icon={IconClass.ChevronRight} onClick={this.onNextButtonClick} />
                    </div>
                    <div className={canvasContainerClassName} ref={(e: HTMLDivElement) => this.canvasContainerElement = e}>
                        <canvas ref={(e: HTMLCanvasElement) => this.canvasElement = e} />
                    </div>
                </div>
            </PS_PDF_VIEWER>
        )
    }

    private renderPage(pageNumber: number): void {
        this.isPageRendering = true;

        this.state.pdfInstance.getPage(pageNumber).then((page: PDFPageProxy) => {
            if (!this.canvasElement || !this.canvasContainerElement) {
                return;
            }
            let pdfViewPort: PDFPageViewport = page.getViewport({ scale: 1 });

            pdfViewPort = page.getViewport({ scale: this.canvasContainerElement.offsetWidth / pdfViewPort.width });
            const context: CanvasRenderingContext2D = this.canvasElement.getContext('2d') as CanvasRenderingContext2D;
            this.canvasElement.height = pdfViewPort.height;
            this.canvasElement.width = pdfViewPort.width;

            const renderTask: PDFRenderTask = page.render({
                canvasContext: context,
                viewport: pdfViewPort
            });

            renderTask.promise.then(() => {
                this.isPageRendering = false;
                if (!!this.pageNumberPending) {
                    this.renderPage(this.pageNumberPending);
                    this.pageNumberPending = null;
                }
            });
        })
    }

    private queueRenderPage(pageNumber: number): void {
        if (this.isPageRendering) {
            this.pageNumberPending = pageNumber;
        } else {
            this.renderPage(pageNumber);
        }
    }

    private onPreviousButtonClick(): void {
        if (this.state.currentPageNumber === 1) {
            return;
        }
        this.setState({ currentPageNumber: this.state.currentPageNumber - 1 }, () => this.queueRenderPage(this.state.currentPageNumber));
    }

    private onNextButtonClick(): void {
        if (this.state.currentPageNumber === this.state.totalPageCount) {
            return;
        }
        this.setState({ currentPageNumber: this.state.currentPageNumber + 1 }, () => this.queueRenderPage(this.state.currentPageNumber));
    }
}


declare global {
    namespace JSX {
        interface IntrinsicElements {
            [PS_PDF_VIEWER]: any
        }
    }
}

export default PdfViewer;