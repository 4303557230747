import {Link} from 'react-router-dom';
import * as React from 'react';
import {PowersysCategory} from '../../../pages/products-page/services';
import {RouteName} from '../../../route';

interface SubItemProps {
    category: PowersysCategory;
    onProductLinkClick: (targetSelector: string) => void;
}

export function SubItem({category, onProductLinkClick}: SubItemProps): JSX.Element {
    const categoryLink: string = `${RouteName.Products}#category-${category.id}`;

    return (
        <div key={category.id}>
            <Link to={categoryLink} onClick={() => onProductLinkClick(`#category-${category.id}`)} className="indent_1">
                {category.name}
            </Link>
            {category.groups?.map((subCat) => (
                <Link
                    key={subCat.id}
                    to={`${categoryLink}-${subCat.id}`}
                    onClick={() => onProductLinkClick(`#category-${category.id}-${subCat.id}`)}
                    className="indent_2"
                >
                    {subCat.description}
                </Link>
            ))}
        </div>
    );
}
