import Axios, { AxiosResponse } from 'axios';
export interface ItemFile {
    image: string;
    pdf: string;
}

export function loadItemFilesService(id: string): Promise<ItemFile> {
    return Axios.post('api/pages.products.loadItemData.php', `id=${id}`)
        .then((response: AxiosResponse<Array<ItemFile>>) => {
            return response.data[0];
        }).catch(() => ({
            image: '',
            pdf: ''
        }));
}