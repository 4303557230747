import * as React from 'react';
import { isEmpty, isMinLengthValid } from '../../../utils';
import { loadSubCategoriesService, addSubCategoriesService, updateSubCategoriesService } from './services';
import { Accordion, AccordionProps } from '../../../components/accordion';
import { SubCategory } from './types';
import { loadCategoriesService } from '../edit-category/services';
import { Category } from '../edit-category/types';

const MP_EDIT_SUB_CATEGORY_PAGE: 'mp-edit-sub-category-page' = 'mp-edit-sub-category-page';

interface EditSubCategoryPageProps {
    authorised: boolean;
}

interface EditSubCategoryPageState {
    categories: Array<Category>;
    subCategories: Array<SubCategory>;
}


export class EditSubCategoryPage extends React.Component<EditSubCategoryPageProps, EditSubCategoryPageState> {

    constructor(props: EditSubCategoryPageProps) {
        super(props);

        this.state = {
            categories: [],
            subCategories: []
        }
    }

    componentDidMount() {
        this.loadSubCategories();
        loadCategoriesService().then(result => this.setState({ categories: result }));
    }

    private loadSubCategories() {
        loadSubCategoriesService().then(result => this.setState({ subCategories: [] }, () => this.setState({ subCategories: result })))
    }

    render() {
        if (!this.isDataLoaded()) {
            return <div>Wczytywanie...</div>;
        }

        return (
            <div className={MP_EDIT_SUB_CATEGORY_PAGE}>
                {this.renderSubCategories()}
            </div>
        );
    }

    private isDataLoaded(): boolean {
        return !isEmpty(this.state.categories) && !isEmpty(this.state.subCategories) && this.props.authorised;
    }

    private renderSubCategories(): JSX.Element {
        const accordionRows: Array<AccordionProps> = [];

        accordionRows.push({
            label: 'Dodaj nową podkategorię',
            className: 'valid',
            content: this.renderSubCategory({
                id: '',
                itemCategoryId: '',
                content: '',
                description: ''
            }, false)
        });

        this.state.subCategories.forEach(subCategory => {
            accordionRows.push({
                label: subCategory.description,
                content: this.renderSubCategory(subCategory),
                className: this.isSubcategoryValid(subCategory) ? "" : 'invalid'
            });
        });

        return (
            <div>
                <Accordion accordionRows={accordionRows} />
            </div>
        );
    }

    private isSubcategoryValid(subCategory: SubCategory): boolean {
        return isMinLengthValid(subCategory.content, 10) && isMinLengthValid(subCategory.description, 5);
    }

    private renderSubCategory(subCategory: SubCategory, edit: boolean = true): JSX.Element {
        let descriptionInput: HTMLInputElement;
        let contentInput: HTMLTextAreaElement;
        let categorySelect: HTMLSelectElement;

        return (
            <div>
                <div className={`${MP_EDIT_SUB_CATEGORY_PAGE}-row`}>
                    <span>Kategoria: </span>
                    <select ref={(select: HTMLSelectElement) => categorySelect = select} defaultValue={subCategory.itemCategoryId} >
                        {this.state.categories.map(category =>
                            <option key={category.id} value={category.id}>{category.name}</option>
                        )}
                    </select>
                </div>
                <div className={`${MP_EDIT_SUB_CATEGORY_PAGE}-row`}>
                    <span>Nazwa: </span><input type='text' defaultValue={subCategory.description}
                        ref={(description: HTMLInputElement) => descriptionInput = description} />
                </div>
                <div className={`${MP_EDIT_SUB_CATEGORY_PAGE}-row`}>
                    <span>Kontent: </span><textarea defaultValue={subCategory.content}
                        ref={(textArea: HTMLTextAreaElement) => contentInput = textArea} />
                </div>
                <div className={`${MP_EDIT_SUB_CATEGORY_PAGE}-row`}>
                    {edit ?
                        <button onClick={() => updateSubCategoriesService({
                            id: subCategory.id,
                            itemCategoryId: categorySelect.selectedOptions[0].value,
                            description: descriptionInput.value,
                            content: contentInput.value
                        }).then(() => this.loadSubCategories())}>Aktualizuj</button> :
                        <button onClick={() => addSubCategoriesService({
                            id: '',
                            itemCategoryId: categorySelect.selectedOptions[0].value,
                            description: descriptionInput.value,
                            content: contentInput.value
                        }).then(() => this.loadSubCategories())}>Dodaj</button>}
                </div>
            </div>
        );
    }
}