import Axios, { AxiosResponse } from 'axios';
import { SliderItem } from '../../../../components/slider/types';
import { encodeSliderItem } from './utils';

export function updateSliderItemService(item: SliderItem): Promise<AxiosResponse> {
    item = encodeSliderItem(item);
    const params: string = `id=${item.id}&title=${item.title}&link=${item.link}&image=${item.image}&content=${item.content}`;
    return Axios.post('api/pages.admin.slider.update.php', params)
        .then((response: AxiosResponse) => response.data)
        .catch((error) => alert(error));
}