import * as React from 'react';
import { isEmpty, isMinLengthValid } from '../../../utils';
import { loadCategoriesService, addCategoryService, updateCategoryService } from './services';
import { Category } from './types';
import { AccordionProps, Accordion } from '../../../components/accordion';

const MP_EDIT_CATEGORY_PAGE: 'mp-edit-category-page' = 'mp-edit-category-page';

interface EditCategoryPageProps {
    authorised: boolean;
}

interface EditCategoryPageState {
    categories: Array<Category>;
}


export class EditCategoryPage extends React.Component<EditCategoryPageProps, EditCategoryPageState> {

    constructor(props: EditCategoryPageProps) {
        super(props);

        this.state = {
            categories: []
        }
    }

    componentDidMount() {
        this.loadData();
    }

    private loadData() {
        loadCategoriesService().then(result => this.setState({ categories: result }));
    }


    render() {
        if (isEmpty(this.state.categories) || !this.props.authorised) {
            return <div>Wczytywanie...</div>;
        }

        return (
            <div className={MP_EDIT_CATEGORY_PAGE}>
                {this.renderCategories()}
            </div>
        );
    }

    private renderCategories(): JSX.Element {
        const accordionRows: Array<AccordionProps> = [];

        accordionRows.push({
            label: 'Dodaj nową kategorię...',
            className: 'valid',
            content: this.renderCategory({
                id: '',
                name: ''
            }, false)
        });

        this.state.categories.forEach(category => {
            accordionRows.push(
                {
                    label: category.name,
                    content: this.renderCategory(category),
                    className: isMinLengthValid(category.name, 3) ? '' : 'invalid'
                }
            );
        })

        return (
            <Accordion accordionRows={accordionRows} />
        )
    }

    private renderCategory(category: Category, edit: boolean = true): JSX.Element {
        let nameInput: HTMLInputElement;

        return (
            <div>
                <span>Nazwa: </span>
                <input style={{ width: '400px' }} type='text' defaultValue={category.name} ref={(name: HTMLInputElement) => nameInput = name} />
                {edit ?
                    <button onClick={() => updateCategoryService({
                        id: category.id,
                        name: nameInput.value
                    }).then(() => this.loadData())}>Aktualizuj</button> :
                    <button onClick={() => addCategoryService({
                        id: null as any,
                        name: nameInput.value
                    }).then(() => this.loadData())}>Dodaj</button>}
            </div>
        );
    }
}