import Axios, { AxiosResponse } from 'axios';
import { Item } from '../types';
import { encodeItem, NAME_MIN_LENGTH } from './utils';
import { isMinLengthValid } from '../../../../utils';

export function addItemService(item: Item): Promise<AxiosResponse> {
    if (isItemValid(item)) {
        const encodedItem: Item = encodeItem(item);
        const params: string = `name=${encodedItem.name}&itemGroupId=${encodedItem.itemGroupId}`
            + `&image=${encodedItem.image}&pdf=${encodedItem.pdf}&thumbnail=${encodedItem.thumbnail}`;
        return Axios.post('api/pages.admin.item.add.php', params)
            .then((response: AxiosResponse) => response.data)
            .catch(() => []);
    } else {
        alert('Uzupełnij wszystkie pola!');
        return Promise.reject('Nie uzupełniono wszystkich pól');
    }
}

function isItemValid(item: Item): boolean {
    return isMinLengthValid(item.name, NAME_MIN_LENGTH) &&
        isMinLengthValid(item.image) &&
        isMinLengthValid(item.pdf) &&
        isMinLengthValid(item.thumbnail);
}