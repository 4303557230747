
export enum IconClass {
    Envelope = 'far fa-envelope',
    Bars = 'fas fa-bars',
    Phone = 'fas fa-phone',
    Exit = 'fas fa-times',
    ChevronLeft = 'fas fa-chevron-left',
    ChevronRight = 'fas fa-chevron-right',
    ChevronUp = 'fas fa-chevron-up',
    ChevronDown = 'fas fa-chevron-down',
    Spinner = 'fas fa-spinner',
    Camera = 'fas fa-camera',
    PDF = 'fas fa-file-pdf'
}

export function getMainClass(iconClass: IconClass): string {
    return iconClass.split(' ')[1];
}