import { Item } from '../types';

export const NAME_MIN_LENGTH: number = 2;

export function encodeItem(item: Item): Item {
    return {
        id: item.id,
        itemGroupId: item.itemGroupId,
        name: encodeURIComponent(item.name),
        image: encodeURIComponent(item.image || ''),
        thumbnail: encodeURIComponent(item.thumbnail || ''),
        pdf: encodeURIComponent(item.pdf || '')
    };
}