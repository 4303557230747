import * as React from 'react';
import Cookies from 'universal-cookie';
import { CommonTextButton } from '../buttons/CommonButton';
const cookies = new Cookies();

const COOKIE_CONTENT: string = 'Korzystanie z danej witryny oznacza zgodę na wykorzystywanie plików cookies.';
const ACCEPTED_COOKIE_POLICY_NAME: string = 'accepted-cookie-policy';

interface CookiesPolicyState {
    show: boolean;
}

export class CookiesPolicy extends React.Component<{}, CookiesPolicyState> {

    constructor(props: any) {
        super(props);
        this.acceptCookies = this.acceptCookies.bind(this);

        const acceptedCookie: string = cookies.get(ACCEPTED_COOKIE_POLICY_NAME);
        const showCookiePolicy: boolean = !(acceptedCookie && acceptedCookie === 'true');

        this.state = {
            show: showCookiePolicy
        };
    }

    render() {
        if (this.state.show) {
            return (
                <ps-cookies>
                    <div className='ps-cookies'>
                        <div><span>{COOKIE_CONTENT}</span></div>
                        <CommonTextButton onClick={this.acceptCookies} text='Rozumiem' />
                    </div>
                </ps-cookies>
            );
        }
        return <></>;
    }

    private acceptCookies() {
        const cd: Date = new Date();
        const expDate: Date = new Date(cd.getFullYear() + 1, cd.getMonth(), cd.getDate());
        cookies.set(ACCEPTED_COOKIE_POLICY_NAME, 'true', { expires: new Date(expDate) });
        this.setState({ show: false });
    }
}


declare global {
    namespace JSX {
        interface IntrinsicElements {
            'ps-cookies': any
        }
    }
}

export default CookiesPolicy;