import * as React from 'react';
import { isMobileWidth } from '../../utils/deviceWidth';
import { NavigationBarDesktop } from './NavigationBarDesktop';
import { NavigationBarMobile } from './NavigationBarMobile';
import { PowersysCategory } from '../../pages/products-page/services';

interface NavigationBarProps {
    itemsTree: Array<PowersysCategory>;
    logoImage: string;
}

interface NavigationBarState {
    isMobile: boolean;
}

export class NavigationBar extends React.Component<NavigationBarProps, NavigationBarState> {

    constructor(props: NavigationBarProps) {
        super(props);

        this.onWindowResize = this.onWindowResize.bind(this);

        this.state = {
            isMobile: false
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize)
    }

    render() {
        return this.state.isMobile ?
            <NavigationBarMobile logoImage={this.props.logoImage} itemsTree={this.props.itemsTree} /> :
            <NavigationBarDesktop logoImage={this.props.logoImage} itemsTree={this.props.itemsTree} />;
    }

    private onWindowResize(): void {
        this.setState({ isMobile: isMobileWidth() });
    }
}