import * as React from 'react';
import { isEmpty, isMinLengthValid } from '../../../utils';
import { loadItemsService, addItemService, loadSubCategoriesService, updateItemService } from './services';
import { Accordion, AccordionProps } from '../../../components/accordion';
import { Item, SubCategory } from './types';
import { ImageInput } from '../../../components/image-input';
import { NAME_MIN_LENGTH } from './services/utils';
import { PDFInput } from '../../../components/pdf-input';

const MP_EDIT_ITEM_PAGE: 'mp-edit-item-page' = 'mp-edit-item-page';

interface EditItemPageProps {
    authorised: boolean;
}

interface EditItemPageState {
    items: Array<Item>;
    subCategories: Array<SubCategory>;
}


export class EditItemPage extends React.Component<EditItemPageProps, EditItemPageState> {

    private errorStyle: React.CSSProperties = { color: 'red' };

    constructor(props: EditItemPageProps) {
        super(props);

        this.state = {
            items: [],
            subCategories: []
        }
    }

    componentDidMount() {
        this.loadItems();
        loadSubCategoriesService().then(result => this.setState({ subCategories: result }));
    }

    private loadItems() {
        loadItemsService().then(result => this.setState({ items: [] }, () => this.setState({ items: result })));
    }

    render() {
        if (!this.isLoaded()) {
            return <div>Wczytywanie...</div>;
        }

        return (
            <div className={MP_EDIT_ITEM_PAGE}>
                {this.renderItems()}
            </div>
        );
    }

    private isLoaded(): boolean {
        return !isEmpty(this.state.items) && !isEmpty(this.state.subCategories) && this.props.authorised;
    }

    private renderItems(): JSX.Element {
        const accordionRows: Array<AccordionProps> = [];

        accordionRows.push({
            label: 'Dodaj nowe urządzenie',
            content: this.renderItem({
                id: '',
                itemGroupId: '',
                thumbnail: '',
                name: '',
            }, false),
            className: 'valid'
        });

        this.state.items.forEach(item => {
            accordionRows.push({
                label: `(${item.id}) ${item.name}`,
                content: this.renderItem(item),
                className: this.isValidItem(item) ? '' : 'invalid'
            });
        });

        return <Accordion accordionRows={accordionRows} />
    }

    private renderItem(item: Item, edit: boolean = true): JSX.Element {
        let imageInput: ImageInput;
        let thumnnailInput: ImageInput;
        let nameInput: HTMLInputElement;
        let subCategorySelect: HTMLSelectElement;
        let pdfInput: PDFInput;
        const rowItem: string = 'row-item';

        return (
            <div>
                {!!!item.hasImage && edit && <div style={this.errorStyle}>{'Brak obrazka'}</div>}
                {!!!item.hasPdf && edit && <div style={this.errorStyle}>{'Brak PDF'}</div>}
                <div className={rowItem}>
                    <select ref={(select: HTMLSelectElement) => subCategorySelect = select} defaultValue={item.itemGroupId} >
                        {this.state.subCategories.map(subCategory =>
                            <option key={subCategory.id} value={subCategory.id}>{subCategory.description}</option>
                        )}
                    </select>
                </div>
                <div className={rowItem}>
                    <span>Nazwa: </span><input type='text' defaultValue={item.name} ref={(name: HTMLInputElement) => nameInput = name} />
                </div>
                <div className={rowItem}>Miniaturka:<ImageInput type='JPG' exactWidth={200} exactHeight={250}
                    maxSizeKB={20} defaultImage={item.thumbnail} ref={(i: ImageInput) => thumnnailInput = i} /></div>
                <div className={rowItem}>Obrazek:<ImageInput type='PNG'
                    maxSizeKB={600} defaultImage={item.image} ref={(i: ImageInput) => imageInput = i} /></div>
                <div className={rowItem}><PDFInput maxSizeKB={3000} ref={(pdf: PDFInput) => pdfInput = pdf} /></div>
                <div className={rowItem}>
                    {edit ? <button onClick={() => updateItemService({
                        id: item.id,
                        name: nameInput.value,
                        itemGroupId: subCategorySelect.selectedOptions[0].value,
                        image: imageInput.getImageString(),
                        thumbnail: thumnnailInput.getImageString(),
                        pdf: pdfInput.getPDFString()
                    }).then(() => this.loadItems())}>Aktualizuj</button> :
                        <button onClick={() => addItemService({
                            id: '',
                            name: nameInput.value,
                            itemGroupId: subCategorySelect.selectedOptions[0].value,
                            image: imageInput.getImageString(),
                            thumbnail: thumnnailInput.getImageString(),
                            pdf: pdfInput.getPDFString()
                        }).then(() => this.loadItems())}>Dodaj</button>}
                </div>
            </div>
        );
    }

    private isValidItem(item: Item): boolean {
        return isMinLengthValid(item.name, NAME_MIN_LENGTH) && !!item.hasImage && !!item.hasPdf;
    }
}