import * as React from 'react';
import {PowersysCategory, PowersysGroup} from './services/loadItems.service';
import {scrollToProductAnchor} from '../../utils/scrollUtils';
import {ProductAccordion} from './product-accordion/ProductAccordion';
import {ProductBox} from './product-box/ProductBox';

const PRODUCTS_PAGE_CLASS_NAME: string = 'products-page';

export interface ProductsProps {
    itemsTree: Array<PowersysCategory>;
}

export class ProductsPage extends React.Component<ProductsProps, {}> {
    componentDidMount() {
        scrollToProductAnchor();
    }

    render() {
        return (
            <products-page>
                <div className={PRODUCTS_PAGE_CLASS_NAME}>
                    {this.props.itemsTree.map((c) => {
                        return this.renderCategory(c);
                    })}
                </div>
            </products-page>
        );
    }

    private renderCategory(cat: PowersysCategory): JSX.Element {
        const {id: catId} = cat;
        return (
            <div id={this.hashId(catId)} key={catId} className={`${PRODUCTS_PAGE_CLASS_NAME}__category`}>
                <div className={`${PRODUCTS_PAGE_CLASS_NAME}__category__title`}>
                    <h1>{cat.name}</h1>
                </div>
                <div className={`${PRODUCTS_PAGE_CLASS_NAME}__group__container`}>
                    {cat.groups.map((g) => {
                        return this.renderGroup(g, catId);
                    })}
                </div>
            </div>
        );
    }

    private renderGroup(group: PowersysGroup, categoryId: string): JSX.Element {
        const {id} = group;
        return (
            <div key={id} className={`${PRODUCTS_PAGE_CLASS_NAME}__group__container__item`}>
                <h3 id={this.hashId(categoryId, id)}>{group.description}</h3>
                <div
                    className={`${PRODUCTS_PAGE_CLASS_NAME}__group__container__item__db`}
                    dangerouslySetInnerHTML={{__html: group.content}}
                />
                <ProductBox key={`box-${id}`} items={group.items} />
                <ProductAccordion key={`acc-${id}`} items={group.items} />
            </div>
        );
    }

    private hashId(categoryId: string, groupId?: string): string {
        if (!groupId) {
            return `category-${categoryId}`;
        }
        return `category-${categoryId}-${groupId}`;
    }
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'products-page': any;
        }
    }
}
