import Axios, { AxiosResponse } from 'axios';
import { SubCategory } from '../types';
import { encodeSubCategory } from './utils';

export function updateSubCategoriesService(subCategory: SubCategory): Promise<AxiosResponse> {
    subCategory = encodeSubCategory(subCategory);
    const params: string = `id=${subCategory.id}&itemCategoryId=${subCategory.itemCategoryId}&description=${subCategory.description}&content=${subCategory.content}`;

    return Axios.post('api/pages.admin.subCategory.update.php', params)
        .then((response: AxiosResponse) => response.data)
        .catch((error) => alert(error));
}