import {Link} from 'react-router-dom';
import * as React from 'react';
import {PowersysCategory} from '../../pages/products-page/services';
import {scrollToProduct} from '../../utils';
import {RouteName} from '../../route';
import {IconClass} from '../../icons';
import {SubItem} from './ui/SubItem';

const PS_NAV_BAR_DESKTOP = 'ps-navigation-bar-desktop';

interface NavigationBarDesktopProps {
    itemsTree: Array<PowersysCategory>;
    logoImage: string;
}

interface NavigationBarDesktopState {
    isProductMenuOpened: boolean;
}

export class NavigationBarDesktop extends React.Component<NavigationBarDesktopProps, NavigationBarDesktopState> {
    private navigationMenu = React.createRef<HTMLDivElement>();

    constructor(props: NavigationBarDesktopProps) {
        super(props);

        this.toggleProductMenu = this.toggleProductMenu.bind(this);
        this.closeProductMenu = this.closeProductMenu.bind(this);
        this.onProductLinkClick = this.onProductLinkClick.bind(this);

        this.state = {
            isProductMenuOpened: false
        };
    }

    public render() {
        return (
            <>
                <div className={PS_NAV_BAR_DESKTOP}>
                    <div className={`${PS_NAV_BAR_DESKTOP}__logo`}>
                        <Link to={RouteName.Home}>
                            <img src={this.props.logoImage} alt="Powersys logo" draggable={false} />
                        </Link>
                    </div>
                    <div ref={this.navigationMenu} className={`${PS_NAV_BAR_DESKTOP}__menu`}>
                        <ul>
                            {this.renderMenuItem(RouteName.Home, 'Strona główna')}
                            {this.renderProductsMenuItem()}
                            {this.renderMenuItem(RouteName.Manual, 'Dobór prostownika')}
                            {this.renderMenuItem(RouteName.Contact, 'Kontakt')}
                            {this.renderMenuItem(RouteName.About, 'O Firmie')}
                        </ul>
                    </div>
                    {this.state.isProductMenuOpened && this.renderProductsMenuContent()}
                </div>
            </>
        );
    }

    private renderMenuItem(link: string, name: string): JSX.Element {
        return (
            <li>
                <Link onClick={this.closeProductMenu} to={link}>
                    {name}
                </Link>
            </li>
        );
    }

    private renderProductsMenuItem(): JSX.Element {
        const arrowClass: string = this.state.isProductMenuOpened ? IconClass.ChevronUp : IconClass.ChevronDown;
        return (
            <li>
                <span onClick={this.toggleProductMenu}>
                    Produkty
                    <i className={arrowClass} />
                </span>
            </li>
        );
    }

    private renderProductsMenuContent(): JSX.Element {
        return (
            <div className={`${PS_NAV_BAR_DESKTOP}__products`}>
                {this.props.itemsTree?.map((category) =>
                    SubItem({category, onProductLinkClick: (targetSelector: string) => this.onProductLinkClick(targetSelector)})
                )}
            </div>
        );
    }

    private toggleProductMenu(): void {
        this.setState((prev) => ({isProductMenuOpened: !prev.isProductMenuOpened}));
    }

    private onProductLinkClick(targetSelector: string): void {
        scrollToProduct(targetSelector);
        this.closeProductMenu();
    }

    private closeProductMenu(): void {
        this.setState({isProductMenuOpened: false});
    }
}
