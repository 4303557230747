import * as React from 'react';
import { isDesktopWidth } from '../../utils/deviceWidth';
import { AuthRouteName } from '../../route';
import { Link } from 'react-router-dom';
import { UserType } from '../../auth/services';

const PS_FOOTER: 'ps-footer' = 'ps-footer';

interface FooterProps {
    userType: UserType;
}

export class Footer extends React.Component<FooterProps, {}> {

    private isDesktop: boolean;

    constructor(props: FooterProps) {
        super(props);

        this.isDesktop = isDesktopWidth();
    }

    render() {
        return (
            <PS_FOOTER>
                <div className={PS_FOOTER}>
                    {this.shouldRenderMenu() && <div>
                        {this.renderMenuItem(AuthRouteName.EditSlider, 'Edytuj slider')}
                        {this.renderMenuItem(AuthRouteName.EditCategory, 'Edytuj kategorie')}
                        {this.renderMenuItem(AuthRouteName.EditSubCategory, 'Edytuj podkategorie')}
                        {this.renderMenuItem(AuthRouteName.EditItem, 'Edytuj towar')}
                    </div>}
                </div>
            </PS_FOOTER>
        )
    }

    private renderMenuItem(link: string, name: string): JSX.Element {
        return (
            <Link to={link}>{name}</Link>
        );
    }

    private shouldRenderMenu() {
        return this.isDesktop && this.props.userType === UserType.LOGGED_USER;
    }
}


declare global {
    namespace JSX {
        interface IntrinsicElements {
            [PS_FOOTER]: any
        }
    }
}