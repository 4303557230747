export enum RouteName {
    Home = '/',
    Products = '/products',
    Contact = '/contact',
    About = '/about',
    Manual = '/manual'
}

export enum AuthRouteName {
    EditSlider = '/edit-slider',
    EditCategory = '/edit-category',
    EditSubCategory = '/edit-sub-category',
    EditItem = '/edit-item'
}