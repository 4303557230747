import * as React from "react";

export function ManualPageContent(): JSX.Element {
    return (
        <div>
            <div>
                <p><b>Zasady doboru prostowników do baterii trakcyjnych</b></p>
                <p>Obecnie w urządzeniach trakcyjnych występuje duża różnorodność baterii, które muszą spełniać
                specyficzne wymagania dotyczące ładowania, dlatego bateria wraz z prostownikiem często muszą
                      stanowić nierozewalny system.</p>
                <p>Aby prawidłowo dopasować prostownik do baterii należy zwrócić uwagę na tryb pracy, rodzaj i&nbsp;konstrukcję
                     baterii oraz wymagany czas do naładowania baterii.</p>
                <p><b>Charakterystyki ładowania</b></p>
                <p>W normie DIN 41772 opisującej parametry półprzewodnikowych prostowników do ładowania baterii
                    zdefiniowane są następujące podstawowe charakterystyki ładowania:</p>

                {renderDotsItem('W', 'charakterystyka stałomocowa')}
                {renderDotsItem('U', 'charakterystyka stałonapięciowa')}
                {renderDotsItem('I', 'charakterystyka stałoprądowa')}
                {renderDotsItem('0', 'automatyczne przełączenie charakterystyki')}
                {renderDotsItem('a', 'automatyczne wyłączenie')}

                <p>W prostownikach stosuje się najczęściej następujące charakterystyki ładowania:</p>
                <p><b>Charakterystyki ładowania typu W:</b><span style={{ color: 'red' }}> W,   Wa,  W0Wa,  WU, WUIa, WUWa</span></p>
                <p><b>Charakterystyki ładowania typu U:</b><span style={{ color: 'red' }}> U</span></p>
                <p><b>Charakterystyki ładowania typu I:</b><span style={{ color: 'red' }}> I,   Ia,   I0Ia,   IU,   IUW,   IUIa</span></p>
                <p>Prostowniki muszą spełniać określone wymagania dotyczące tolerancji prądu i&nbsp;napięcia.</p>
                <p>Przy charakterystykach typu I wg DIN 41773: ± 2% wartości prądu, ± 1% wartości napięcia,
                    przy charakterystykach typu W wg DIN 41774: ± 0,05 V/ogniwo.</p>
                <p>Do ładowania baterii zaleca  się stosować urządzenia ze stabilizacją prądu i&nbsp;napięcia ponieważ tylko takie
                    potrafią skompensować niekorzystny wpływ  wahań sieci zasilającej.</p>
                <p style={{ fontStyle: 'italic' }}> Więcej informacji na temat ładowania baterii kwasowych i&nbsp;zasadowych znajdą Państwo w poniższych artykułach.</p>

                <a href='./fbc.pdf' style={{ display: 'block' }}>Zasady doboru prostowników do baterii trakcyjnych</a>
                <a href="./nicd.pdf" style={{ display: 'block' }}>Zasady doboru prostowników do baterii zasadowych NiCd</a>
            </div>
        </div>
    );
}

function renderDotsItem(char: string, text: string): JSX.Element {
    const dots: string = '.'.repeat(15);
    return (
        <div>
            <b style={{ display: 'inline-block', width: '15px' }}>{char}</b>
            <span>{dots} {text}</span>
        </div>
    );
}