import * as React from 'react';

const PS_ACCORDION: 'ps-accordion' = 'ps-accordion';

export interface AccordionProps {
    label: string;
    content: JSX.Element;
    className?: string;
}

export interface AccordionsProps {
    accordionRows: Array<AccordionProps>;
}

export class Accordion extends React.Component<AccordionsProps, {}> {

    constructor(props: AccordionsProps) {
        super(props);

        this.onLabelClick = this.onLabelClick.bind(this);
    }

    render() {
        return (
            <PS_ACCORDION>
                {this.renderAccordionRows(this.props.accordionRows)}
            </PS_ACCORDION>
        );
    }

    private renderAccordionRows(accordionRows: Array<AccordionProps>): JSX.Element {
        return (
            <>
                {accordionRows.map((accordion: AccordionProps) => {
                    return (
                        <div key={accordion.label} className={`accordion-item ${accordion.className}`}>
                            <div className='label' dangerouslySetInnerHTML={{ __html: accordion.label }} onClick={(event) => this.onLabelClick(event)} />
                            <div className='content'>{accordion.content}</div>
                        </div>
                    )
                })}
            </>
        );
    }

    private onLabelClick(event: React.MouseEvent) {
        if (event?.currentTarget?.parentElement) {
            event.currentTarget.parentElement.classList.toggle('active');
        }
    }
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            [PS_ACCORDION]: any
        }
    }
}