export enum ButtonType {
    NEXT,
    PREVIOUS,
    BULLET
}

export interface SliderItem {
    id: string;
    link: string;
    title: string;
    content: string;
    image: string;
}