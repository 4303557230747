import * as React from 'react';
import { ImageType } from './types';
const PS_IMAGE_INPUT: 'ps-image-input' = 'ps-image-input';

interface ImageInputProps {
    type: ImageType;
    defaultImage?: string;
    maxSizeKB?: number;
    exactWidth?: number;
    exactHeight?: number;
}

interface ImageInputState {
    imageSource: string;
}

const KILO: number = 1024;
const DEFAULT_MAX_IMAGE_SIZE_BYTES = 512 * KILO;

export class ImageInput extends React.Component<ImageInputProps, ImageInputState> {

    private acceptTypes: string = '';
    private readonly maxSizeBytes: number;


    constructor(props: ImageInputProps) {
        super(props);
        this.state = {
            imageSource: this.props.defaultImage || ''
        }

        if (props.maxSizeKB) {
            this.maxSizeBytes = props.maxSizeKB * KILO;
        } else {
            this.maxSizeBytes = DEFAULT_MAX_IMAGE_SIZE_BYTES;
        }

        switch (props.type) {
            case "JPG":
                this.acceptTypes = 'image/jpeg';
                break;
            case "PNG":
                this.acceptTypes = 'image/png';
                break;
            case "GIF":
                this.acceptTypes = 'image/gif';
                break;
        }

        this.validateImage = this.validateImage.bind(this);

    }

    render() {
        const size: string = this.props.exactWidth && this.props.exactHeight ? `${this.props.exactWidth}x${this.props.exactHeight}, ` : '';
        const imageAlt: string = `Wybierz zdjęcie: (${this.acceptTypes}, ${size}max ${this.maxSizeBytes / KILO} KB)`;
        return (
            <PS_IMAGE_INPUT>
                <div className={PS_IMAGE_INPUT}>
                    <input type='file' accept={this.acceptTypes} onChange={(event: React.ChangeEvent) => this.validateImage(event)} />
                    <img src={this.state.imageSource} alt={imageAlt} />
                </div>
            </PS_IMAGE_INPUT>
        )
    }

    public getImageString(): string {
        return this.state.imageSource;
    };

    private shouldValidateSize(): boolean {
        return !!this.props.exactHeight || !!this.props.exactWidth;
    }

    private validateImage(event: React.ChangeEvent) {
        const input: HTMLInputElement = event.currentTarget as HTMLInputElement;
        let isImageValid: boolean = true;
        let errorMessage: string = '';
        if (input && input.files !== null) {
            const image: File = input.files[0];
            if (image.size > this.maxSizeBytes) {
                errorMessage += `Przekroczono dozwolony rozmiar pliku!\nMaksymalny rozmiar: ${this.maxSizeBytes} bajtów.\nRozmiar obrazka: ${image.size} bajtów.\n`;
                isImageValid = false;
            }

            if (image.type !== this.acceptTypes) {
                errorMessage += `Niepoprawny typ obrazka!\nOczekiwany typ: ${this.acceptTypes}\n`;
                isImageValid = false;
            }

            if (isImageValid) {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(image);

                fileReader.onload = (evnt) => {
                    const img = new Image();
                    img.src = evnt.target!.result + '';
                    img.onload = () => {
                        if (this.shouldValidateSize()) {
                            if (img.width !== this.props.exactWidth || img.height !== this.props.exactHeight) {
                                errorMessage += `Wymagany rozmiar obrazka ${this.props.exactWidth} x ${this.props.exactHeight}`;
                                isImageValid = false;
                                input.value = '';
                                this.setState({ imageSource: this.props.defaultImage || '' });
                                alert(errorMessage);
                            } else {
                                this.setState({ imageSource: img.src });
                            }
                        } else {
                            this.setState({ imageSource: img.src });
                        }

                    }
                };
            }

            if (!isImageValid) {
                input.value = '';
                alert(errorMessage);
            }
        }
    }
}


declare global {
    namespace JSX {
        interface IntrinsicElements {
            [PS_IMAGE_INPUT]: any
        }
    }
}