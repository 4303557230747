import Axios, { AxiosResponse } from 'axios';
import { SliderItem } from '../../../../components/slider/types';
import { encodeSliderItem } from './utils';

export function addSliderItemService(item: SliderItem): Promise<AxiosResponse> {
    item = encodeSliderItem(item);
    const params: string = `title=${item.title}&link=${item.link}&image=${item.image}&content=${item.content}`;
    return Axios.post('api/pages.admin.slider.add.php', params)
        .then((response) => response.data)
        .catch((error) => alert(error));
}