import * as React from 'react';
import { CommonImages } from './services';

interface ContentPageProps {
    commonImages: CommonImages;
}

export class ContentPage extends React.Component<ContentPageProps, {}> {

    constructor(props: ContentPageProps) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <content-page>
                <div className='content-page'>
                    <div className='content-page__left'>
                        <img src={this.props.commonImages.environment} alt='środowisko' draggable={false} />
                        <img src={this.props.commonImages.electrics} alt='elektryczne urządzenia' draggable={false} />
                    </div>
                    <div className='content-page__main'>
                        {this.props.children}
                    </div>
                </div>
            </content-page>
        )
    }
}


declare global {
    namespace JSX {
        interface IntrinsicElements {
            'content-page': any
        }
    }
}

export default ContentPage;