export function isEmpty(array: Array<any>): boolean {
    if (!array) {
        return true;
    }
    return array.length === 0;
}

export function lastElement<T>(array: Array<T>) {
    if (!isEmpty(array)) {
        return array[array.length - 1];
    }
    return null;
}