import * as React from 'react';
import pretty from 'pretty';
import { loadSliderItemsService } from '../../../components/slider/services';
import { SliderItem } from '../../../components/slider/types';
import { isEmpty } from '../../../utils/arrayUtils';
import { Accordion, AccordionProps } from '../../../components/accordion';
import { addSliderItemService, deleteSliderItemService, updateSliderItemService } from './services';
import { ImageInput } from '../../../components/image-input';
import { isMinLengthValid } from '../../../utils';

const MP_EDIT_SLIDER_PAGE: 'mp-edit-slider-page' = 'mp-edit-slider-page';

interface EditSliderPageProps {
    authorised: boolean;
}

interface EditSliderPageState {
    sliderItems: Array<SliderItem>;
}

export class EditSliderPage extends React.Component<EditSliderPageProps, EditSliderPageState> {

    constructor(props: EditSliderPageProps) {
        super(props);
        this.state = {
            sliderItems: []
        }
    }

    componentDidMount() {
        this.loadData();
    }

    private loadData() {
        loadSliderItemsService().then((items) => this.setState({ sliderItems: [] }, () => this.setState({ sliderItems: items })));
    }

    render() {
        if (isEmpty(this.state.sliderItems) || !this.props.authorised) {
            return <div>Wczytywanie...</div>;
        }

        return (
            <div className={MP_EDIT_SLIDER_PAGE}>
                {this.renderSliderAccordions()}
            </div>
        );
    }

    private renderSliderAccordions(): JSX.Element {

        const accordionRows: Array<AccordionProps> = [];
        accordionRows.push({
            label: 'Dodaj nowy slider...',
            className: 'valid',
            content: this.renderSliderItem({
                id: '',
                title: '',
                content: '',
                link: '',
                image: ''
            }, false)
        });

        this.state.sliderItems.forEach((item) =>
            accordionRows.push({
                label: item.title,
                content: this.renderSliderItem(item),
                className: this.isSliderItemValid(item) ? '' : 'invalid'
            })
        );

        return <Accordion accordionRows={accordionRows} />;
    }

    private renderSliderItem(item: SliderItem, edit: boolean = true): JSX.Element {
        let titleInput: HTMLInputElement;
        let linkInput: HTMLInputElement;
        let imageInput: ImageInput;
        let contentInput: HTMLTextAreaElement;

        return (
            <div className={MP_EDIT_SLIDER_PAGE + '-item'}>
                <div><ImageInput type='JPG' maxSizeKB={150} exactHeight={300} exactWidth={550} defaultImage={item.image} ref={(i: ImageInput) => imageInput = i} /></div>
                <div><span>Tytuł: </span><input type='text' defaultValue={item.title} ref={(title: HTMLInputElement) => titleInput = title} /></div>
                <div><span>Link: </span><input type='text' defaultValue={item.link} ref={(link: HTMLInputElement) => linkInput = link} /></div>
                <div><span>Kontent: </span>
                    <textarea defaultValue={pretty(item.content)} ref={(content: HTMLTextAreaElement) => contentInput = content} />
                </div>
                {edit ?
                    <div>
                        <button onClick={() => updateSliderItemService({
                            id: item.id,
                            title: titleInput.value,
                            content: contentInput.value,
                            link: linkInput.value,
                            image: imageInput.getImageString()
                        }).then(() => this.loadData())}>Aktualizuj</button>
                        <button style={{ display: 'none' }} onClick={() => deleteSliderItemService(item.id).then(() => this.loadData())}>Usuń</button>
                    </div> :
                    <button onClick={() => addSliderItemService({
                        id: null as any,
                        title: titleInput.value,
                        content: contentInput.value,
                        link: linkInput.value,
                        image: imageInput.getImageString()
                    }).then(() => this.loadData())}>Dodaj</button>}
            </div>
        );
    }

    private isSliderItemValid(sliderItem: SliderItem): boolean {
        return isMinLengthValid(sliderItem.title, 3) &&
            isMinLengthValid(sliderItem.content, 10) &&
            isMinLengthValid(sliderItem.image, 10);
    }
}