import * as React from 'react';
import {Link} from 'react-router-dom';
import {PowersysCategory} from '../../pages/products-page/services';
import {scrollToProduct} from '../../utils';
import {RouteName} from '../../route';
import {IconClass} from '../../icons';
import {SubItem} from './ui/SubItem';

const PS_NAV_BAR_MOBILE = 'ps-navigation-bar-mobile';

interface NavigationBarMobileProps {
    itemsTree: Array<PowersysCategory>;
    logoImage: string;
}

interface NavigationBarMobileState {
    isOpened: boolean;
    isProductsItemExpanded: boolean;
}

export class NavigationBarMobile extends React.Component<NavigationBarMobileProps, NavigationBarMobileState> {
    private navigationMenu = React.createRef<HTMLDivElement>();

    constructor(props: NavigationBarMobileProps) {
        super(props);

        this.closeMenu = this.closeMenu.bind(this);
        this.onProductLinkClick = this.onProductLinkClick.bind(this);

        this.state = {
            isOpened: false,
            isProductsItemExpanded: false
        };
    }

    public render() {
        return (
            <>
                <div className={PS_NAV_BAR_MOBILE}>
                    <div className={`${PS_NAV_BAR_MOBILE}__logo`}>
                        <Link to={RouteName.Home}>
                            <img src={this.props.logoImage} alt="Powersys logo" draggable={false} />
                        </Link>
                        <div className={`${PS_NAV_BAR_MOBILE}__logo__icons`}>
                            <a className={IconClass.Envelope} href="mailto:powersys@powersys.pl">
                                {' '}
                            </a>
                            <i className={this.state.isOpened ? IconClass.Exit : IconClass.Bars} onClick={() => this.onBurgerMenuClick()} />
                        </div>
                    </div>
                    {this.state.isOpened && (
                        <div ref={this.navigationMenu} className={`${PS_NAV_BAR_MOBILE}__menu`}>
                            <ul>
                                {this.renderMenuItem(RouteName.Home, 'Strona główna')}
                                {this.renderExpandableProductsMenuItem(RouteName.Products, 'Produkty')}
                                {this.renderProductsMenuContent()}
                                {this.renderMenuItem(RouteName.Manual, 'Dobór prostownika')}
                                {this.renderMenuItem(RouteName.Contact, 'Kontakt')}
                                {this.renderMenuItem(RouteName.About, 'O Firmie')}
                            </ul>
                        </div>
                    )}
                </div>
            </>
        );
    }

    private renderMenuItem(link: string, name: string): JSX.Element {
        return (
            <li>
                <Link onClick={this.closeMenu} to={link}>
                    {name}
                </Link>
            </li>
        );
    }

    private renderExpandableProductsMenuItem(link: string, name: string): JSX.Element {
        return (
            <li>
                <Link onClick={() => this.onProductItemClick()} to={link}>
                    {name}
                </Link>
                {this.renderChevronIcon()}
            </li>
        );
    }

    private renderChevronIcon(): JSX.Element {
        const iconClass: string = this.state.isProductsItemExpanded ? IconClass.ChevronUp : IconClass.ChevronDown;
        return <i className={`expandable-icon ${iconClass}`} onClick={() => this.onProductItemClick()} />;
    }

    private onProductItemClick(): void {
        this.setState({isProductsItemExpanded: !this.state.isProductsItemExpanded});
    }

    private renderProductsMenuContent(): JSX.Element {
        if (this.state.isProductsItemExpanded) {
            return (
                <div className={`${PS_NAV_BAR_MOBILE}__products`}>
                    {this.props.itemsTree?.map((category) =>
                        SubItem({category, onProductLinkClick: (targetSelector: string) => this.onProductLinkClick(targetSelector)})
                    )}
                </div>
            );
        }
        return <div />;
    }

    private onProductLinkClick(targetSelector: string): void {
        scrollToProduct(targetSelector);
        this.closeMenu();
    }

    private onBurgerMenuClick(): void {
        this.setState((prev) => ({isOpened: !prev.isOpened}));
    }

    private closeMenu(): void {
        this.setState({isOpened: false});
    }
}
