import * as React from "react";

export function AboutPageContent(): JSX.Element {
    return (
        <div>
            <h4>Misja</h4>
            Misją firmy <b>POWERSYS</b> jest rozumieć potrzeby swoich Klientów oraz oferować lepsze od konkurencyjnych
            rozwiązania. Pragniemy oferować produkty i&nbsp;usługi na najwyższym poziomie technicznym i&nbsp;jakościowym przy zachowaniu umiarkowanych cen.
            <h4>Profil działalności</h4>
            <span><b>POWERSYS</b></span> zajmuje się projektowaniem, produkcją, instalacją oraz serwisem nowoczesnych
            urządzeń energoelektronicznych dla przemysłu energetyki i&nbsp;wojska.W stałej ofercie posiadamy prostowniki do ładowania baterii trakcyjnych dużej mocy.
            <div>Firma <b>POWERSYS</b> chętnie podejmuje się realizacji projektów nietypowych, zbliżonych do zasadniczego profilu produkcji.</div>
            <h4>Jakość i&nbsp;nowoczesność</h4>
            Urządzenia ze znakiem <b>POWERSYS</b> spełniają wymagania polskich i&nbsp;europejskich norm dotyczących bezpieczeństwa,
            kompatybilności elektromagnetycznej a także dyrektyw Unii Europejskiej (znak CE)
            <ul>Wysoką jakość i&nbsp;nowoczesność staramy zapewnić się między innymi przez:
                <li>stosowanie metod symulacji komputerowej</li>
                <li>bieżący kontakt kadry konstruktorskiej z najnowszymi technologiami i&nbsp;podzespołami</li>
                <li>stosowanie najnowszych układów mikroprocesorowych</li>
                <li>korzystanie wyłącznie z wysokiej klasy podzespołów od renomowanych producentów</li>
                <li>monitorowanie urządzeń</li>
            </ul>
        </div>
    );
}