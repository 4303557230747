import { NAVIGATION_BAR_DESKTOP_HEIGHT, NAVIGATION_BAR_MOBILE_HEIGHT } from '../components/navigation-bar/types';
import { isMobileWidth } from './deviceWidth';
import { RouteName } from '../route';

export function scrollToProductAnchor() {
    const href = window.location.href;
    if (href.indexOf('#') > 0) {
        const anchor: string = href.split('#')[1]
        scrollToProduct('#' + anchor);
    }
}

export function scrollToProduct(elementSelector: string) {
    if (window.location.pathname === RouteName.Products) {
        const elementToScroll: HTMLElement = document.querySelector(elementSelector) as HTMLElement;
        if (elementToScroll) {
            const targetPositionY: number = elementToScroll.getBoundingClientRect().y - document.body.getBoundingClientRect().y;
            window.scrollTo(0, targetPositionY - getOffset());
        }
    }
}

function getOffset() {
    return isMobileWidth() ? NAVIGATION_BAR_MOBILE_HEIGHT : NAVIGATION_BAR_DESKTOP_HEIGHT;
}