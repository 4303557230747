import * as React from 'react';
import { SliderItem, ButtonType } from './types';
import { IconClass } from '../../icons';
import { Link } from 'react-router-dom';
import { isEmpty } from '../../utils';

const PS_SLIDER: 'ps-slider' = 'ps-slider';
const PS_SLIDER_SLIDE: string = 'ps-slider-slide';
const ACTIVE_CLASS: string = 'bullet-active';
const CURRENT_RIGHT_CLASS: string = 'current-right';
const NEXT_RIGHT_CLASS: string = 'next-right';
const CURRENT_LEFT_CLASS: string = 'current-left';
const NEXT_LEFT_CLASS: string = 'next-left';

const SLIDING_TIME_MILISEC: number = 1000;
const TIME_BETWEEN_CHANGE_SLIDE: number = 6000;

interface SliderProps {
    sliderItems: Array<SliderItem>;
}

export class Slider extends React.Component<SliderProps, {}> {

    private slideIndex: number = 0;
    private bulletButtonIndex: number;
    private isAutoSlideOn: boolean;
    private isAutoSlidePaused: boolean;
    private sliderItems: Array<SliderItem>

    private sliderButtons: Array<HTMLButtonElement> = [];
    private navigationContainer: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()
    private slideElement: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>()

    constructor(props: SliderProps) {
        super(props);
        this.state = {}
        this.sliderItems = this.props.sliderItems;

        this.show = this.show.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.stopAutoSliding = this.stopAutoSliding.bind(this);
        this.resumeAutoSliding = this.resumeAutoSliding.bind(this);
        this.autoSlideNext = this.autoSlideNext.bind(this);

        this.bulletButtonIndex = 0;
        this.isAutoSlideOn = false;
        this.isAutoSlidePaused = false;
    }

    componentDidMount() {
        if (!isEmpty(this.sliderItems)) {
            if (this.navigationContainer.current) {
                this.sliderButtons = Array.from(this.navigationContainer.current.querySelectorAll('button'));
            }
            if (!this.isAutoSlideOn) {
                this.isAutoSlideOn = true;
                this.autoSlideNext();
            }
        }
    }

    componentWillUnmount() {
        this.sliderButtons = [];
        this.isAutoSlideOn = false;
        this.isAutoSlidePaused = true;
    }

    render() {
        if (isEmpty(this.sliderItems)) {
            return <PS_SLIDER />
        }
        return (
            <PS_SLIDER>
                <div className={PS_SLIDER} onMouseEnter={this.stopAutoSliding} onMouseLeave={this.resumeAutoSliding} ref={this.slideElement}>
                    {this.sliderItems.map(this.renderSlide, this)}

                    <div className={`${PS_SLIDER}-navigation-container`} ref={this.navigationContainer}>
                        <button className={`${PS_SLIDER}-navigation-previous ${IconClass.ChevronLeft}`}
                            onMouseDown={(event: React.MouseEvent) => event.stopPropagation()}
                            onClick={this.previous} />
                        <div className={`${PS_SLIDER}-navigation-bullets`}>
                            {this.renderBulletButtons()}
                        </div>
                        <button className={`${PS_SLIDER}-navigation-next ${IconClass.ChevronRight}`}
                            onMouseDown={(event: React.MouseEvent) => event.stopPropagation()}
                            onClick={this.next} />
                    </div>
                </div>
            </PS_SLIDER >
        )
    }

    public show(buttonType: ButtonType): void {
        if (!this.slideElement.current) {
            return;
        }

        const squareButtons: Array<Element> = Array.from(this.slideElement.current.querySelectorAll('.ps-slider-bullet'));
        const images: Array<Element> = Array.from(this.slideElement.current.querySelectorAll('.' + PS_SLIDER_SLIDE));

        let disable = true;

        for (let i: number = 0; i < this.sliderItems.length; i++) {
            const image: HTMLElement = images[i] as HTMLElement;
            image.classList.remove(NEXT_RIGHT_CLASS, CURRENT_RIGHT_CLASS, NEXT_LEFT_CLASS, CURRENT_LEFT_CLASS);
            image.style.zIndex = '1';
        }

        squareButtons.forEach(button => button.classList.remove(ACTIVE_CLASS));

        const img: HTMLElement = images[this.slideIndex] as HTMLElement;
        img.style.zIndex = '2';

        if (buttonType === ButtonType.NEXT) {
            const nextImg: HTMLElement = images[this.incSlideIndex()] as HTMLElement;

            img.classList.add(CURRENT_RIGHT_CLASS);

            nextImg.classList.add(NEXT_RIGHT_CLASS);
            nextImg.style.zIndex = '3';
        } else if (buttonType === ButtonType.PREVIOUS) {
            const prevImg: HTMLElement = images[this.decSlideIndex()] as HTMLElement;

            img.classList.add(CURRENT_LEFT_CLASS);

            prevImg.classList.add(NEXT_LEFT_CLASS);
            prevImg.style.zIndex = '3';
        } else if (buttonType === ButtonType.BULLET) {
            const bulletImage: HTMLElement = images[this.bulletButtonIndex] as HTMLElement;

            if (this.bulletButtonIndex < this.slideIndex) {
                img.classList.add(CURRENT_LEFT_CLASS);
                bulletImage.classList.add(NEXT_LEFT_CLASS);
            } else if (this.bulletButtonIndex > this.slideIndex) {
                img.classList.add(CURRENT_RIGHT_CLASS);
                bulletImage.classList.add(NEXT_RIGHT_CLASS);
            } else {
                disable = false;
            }

            bulletImage.style.zIndex = '3';
            this.slideIndex = this.bulletButtonIndex;
        }
        this.disableSliderButtons(disable);
        squareButtons[this.slideIndex].className += ' ' + ACTIVE_CLASS;
    }

    private renderSlide(slide: SliderItem): JSX.Element {
        const slideImageProps: React.CSSProperties = {
            backgroundImage: `url(${slide.image})`
        };

        return (
            <div key={slide.image} className={PS_SLIDER_SLIDE}>
                <Link to={slide.link}>
                    <div className={`${PS_SLIDER_SLIDE}-title`} >{slide.title}</div>
                    <div className={`${PS_SLIDER_SLIDE}-image`} style={slideImageProps} />
                </Link>
                <div className={`${PS_SLIDER_SLIDE}-content`} dangerouslySetInnerHTML={{ __html: slide.content }} />
            </div>
        )
    }

    private autoSlideNext(): void {
        if (!this.isAutoSlidePaused && !this.sliderButtons[0].disabled) {
            this.next();
        }
        if (this.isAutoSlideOn) {
            setTimeout(this.autoSlideNext, TIME_BETWEEN_CHANGE_SLIDE);
        }
    }

    private previous(): void {
        this.show(ButtonType.PREVIOUS)
    }

    private next(): void {
        this.show(ButtonType.NEXT)
    }

    private currentImage(n: number) {
        this.bulletButtonIndex = n;
        this.show(ButtonType.BULLET);
    }

    private stopAutoSliding(): void {
        this.isAutoSlidePaused = true;
    }

    private resumeAutoSliding(): void {
        this.isAutoSlidePaused = false;
    }

    private incSlideIndex(): number {
        return this.slideIndex = (++this.slideIndex) % this.sliderItems.length;
    }

    private decSlideIndex(): number {
        return this.slideIndex = this.slideIndex < 1 ? this.sliderItems.length - 1 : --this.slideIndex
    }

    private disableSliderButtons(disable: boolean) {
        if (disable) {
            this.sliderButtons.forEach(b => b.disabled = true);
        }
        setTimeout(() => {
            this.sliderButtons.forEach(b => b.disabled = false);
        }, SLIDING_TIME_MILISEC);
    }

    private renderBulletButtons(): Array<JSX.Element> {
        const buttons: Array<JSX.Element> = [];

        this.sliderItems.forEach((slide, i) => {
            const buttonClassName: string = i === 0 ? `${PS_SLIDER}-bullet` : `${PS_SLIDER}-bullet ${ACTIVE_CLASS}`;
            buttons.push(<button key={i} className={buttonClassName}
                onMouseDown={(event: React.MouseEvent) => event.stopPropagation()}
                onClick={() => this.currentImage(i)}
            />)
        })

        return buttons;
    }
}


declare global {
    namespace JSX {
        interface IntrinsicElements {
            [PS_SLIDER]: any
        }
    }
}

export default Slider;