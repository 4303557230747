import * as React from 'react';
// import { HomeItem } from './HomeItem';
// import industrialImage from '../../images/industrial.png';
import { Slider } from '../../components/slider/Slider';
import { SliderItem } from '../../components/slider/types';

interface HomePageProps {
    sliderItems: Array<SliderItem>;
}

const HOME_PAGE: 'home-page' = 'home-page';

export class HomePage extends React.Component<HomePageProps, {}> {

    constructor(props: HomePageProps) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <HOME_PAGE>
                <Slider sliderItems={this.props.sliderItems} />
            </HOME_PAGE>
        )
    }
}


declare global {
    namespace JSX {
        interface IntrinsicElements {
            [HOME_PAGE]: any
        }
    }
}

export default HomePage;