import * as React from 'react';
import { MouseEventHandler } from 'react';

type CommonButtonProps = {
    onClick?: MouseEventHandler;
}

type CommonTextButtonProps = CommonButtonProps & {
    text: string;
}

type CommonIconButtonProps = CommonButtonProps & {
    icon: string;
}

export function CommonTextButton({ text, onClick }: CommonTextButtonProps): JSX.Element {
    return <button onClick={onClick} className={`ps-common-button-text`} >{text}</button>
}

export function CommonIconButton({ icon, onClick }: CommonIconButtonProps): JSX.Element {
    return <button onClick={onClick} className={`ps-common-button-icon ${icon}`} />
}

