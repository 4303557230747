import * as React from "react";

const EMAIL: string = 'powersys@powersys.pl';

export function ContactPageContent(): JSX.Element {
    return (
        <div>
            <h4>Kontakt</h4>
            <div>
                <div><b>Urządzenia i&nbsp;Systemy Zasilające PowerSYS</b></div>
                <div>ul. Jarowa 5</div>
                <div> 80-217 Gdańsk</div>
                <br />
                <div>NIP: PL9570124778</div>
                <div>REGON:002863998</div>
                <br />
                <div>tel. (58) 345 44 77</div>
                <div>kom. 692 731 003</div>
                <div>FAX:58 739 12 68</div>
                <br />
                <div>adres e-mail: <a href={`mailto:${EMAIL}`}>{EMAIL}</a></div>
                <br />
            </div>
            <div>
                <div>mgr inż. <b>Romuald Polak</b></div>
                <div>konstruktor urządzeń i&nbsp;systemów zasilających</div>
                <div>współwłaściciel</div>
                <div>tel. 692 731 003</div>
            </div>
            <br />
            <div>
                <div>mgr inż. <b>Zbigniew Błaszkiewicz</b></div>
                <div>konstruktor urządzeń i&nbsp;systemów zasilających</div>
                <div>współwłaściciel</div>
                <div>tel. 608 704 060</div>
            </div>
        </div>
    );
}