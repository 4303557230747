import { SliderItem } from '../../../../components/slider/types';

export function encodeSliderItem(item: SliderItem): SliderItem {
    return {
        id: item.id,
        title: encodeURIComponent(item.title),
        link: encodeURIComponent(item.link),
        content: encodeURIComponent(item.content),
        image: encodeURIComponent(item.image)
    };
}