import * as React from 'react';
import { PowersysItem, loadItemFilesService } from '../services';
import { IconClass } from '../../../icons';
import { PdfViewer } from '../../../components/pdf-viewer/PdfViewer';
import { createItemId } from '../utils';

const PS_PRODUCT_ACCORDION: 'ps-product-accordion' = 'ps-product-accordion';

export interface ProductAccordionProps {
    items: Array<PowersysItem>;
}

type Item = PowersysItem &
{
    isActive: boolean,
    isLoading: boolean,
    view: ItemViewMode
};

enum ItemViewMode {
    Image,
    PDF
}

export interface ProductAccordionState {
    isLoading: boolean;
    isOpened: boolean;
    items: Array<Item>;
}


export class ProductAccordion extends React.Component<ProductAccordionProps, ProductAccordionState> {
    constructor(props: ProductAccordionProps) {
        super(props);

        this.onCloseLabelClick = this.onCloseLabelClick.bind(this);
        this.onOpenLabelClick = this.onOpenLabelClick.bind(this);
        this.changeView = this.changeView.bind(this);

        this.state = {
            isOpened: false,
            isLoading: false,
            items: this.props.items.map(item => ({
                ...item,
                isActive: false,
                isLoading: false,
                view: ItemViewMode.Image
            }))
        };
    }

    private accordionElement = React.createRef<HTMLDivElement>();

    render() {
        return (
            <PS_PRODUCT_ACCORDION>
                <span>Wykonania:</span>
                <div ref={this.accordionElement} className='accordion-item'>
                    <div className='accordion-label' >
                        {this.renderLabelButtons()}
                    </div>
                    {this.renderAccordionContent()}
                </div>
            </PS_PRODUCT_ACCORDION>
        );
    }

    private renderLabelButtons(): Array<JSX.Element> {
        return this.state.items.map(item =>
            this.renderLabelStateButton(item)
        );
    }

    private onOpenLabelClick(pItem: Item): void {
        const items: Array<Item> = this.state.items;
        items.forEach(i => {
            i.isActive = false;
            i.isLoading = false;
        });

        const item: Item = items.filter(f => f.id === pItem.id)[0] as Item;

        if (!item?.imgBase64 || !item?.pdfBase64) {
            item.isLoading = true;
            this.setState({ isLoading: true, items }, () => {
                loadItemFilesService(pItem.id).then((result) => {
                    item.imgBase64 = result.image;
                    item.pdfBase64 = result.pdf;
                    item.isActive = true;
                    this.setState({ items, isLoading: false, isOpened: true })
                });
            });
        }

        if (!this.state.isLoading && (item?.imgBase64 || item?.pdfBase64)) {
            item.isActive = true;
            this.setState({ isOpened: true, items });
        }
    }

    private renderLabelStateButton(item: Item): JSX.Element {
        if (this.state.isLoading && item.isLoading) {
            return (
                <span key={item.id}>
                    <button key={item.id} className='label'>{item.name}</button>
                    <button className={`${IconClass.Spinner} label button-loading`} />
                </span>
            );
        }
        if (this.state.isOpened && item.isActive) {
            return (
                <span key={item.id} onClick={this.onCloseLabelClick}>
                    <button key={item.id} className='label'>{item.name}</button>
                    <button className={`${IconClass.Exit} label button-exit`} />
                </span>
            )
        }
        return (
            <span id={createItemId(item)} key={item.id} onClick={() => this.onOpenLabelClick(item)}>
                <button key={item.id} className='label'>{item.name}</button>
                <button className={`${IconClass.ChevronDown} label button-arrow-down`} />
            </span>
        );
    }


    private renderAccordionContent(): JSX.Element {
        if (!this.state.isOpened) {
            return <div />;
        }

        const activeItem: Item = this.state.items.filter(f => f.isActive)[0] as Item;

        return (
            <div className='accordion-content'>
                {!!activeItem ?
                    <div className='accordion-content-top'>
                        <i className={viewButtonClassName(activeItem, ItemViewMode.Image, IconClass.Camera)}
                            onClick={() => this.changeView(activeItem, ItemViewMode.Image)} />
                        <i className={viewButtonClassName(activeItem, ItemViewMode.PDF, IconClass.PDF)}
                            onClick={() => this.changeView(activeItem, ItemViewMode.PDF)} />
                    </div> :
                    <div className='accordion-content-bottom' />}
                {this.renderMainColumn(activeItem)}
            </div>
        );

        function viewButtonClassName(_activeItem: Item, view: ItemViewMode, iconClass: IconClass): string {
            return `${iconClass}${_activeItem.view === view ? ' view-button-active' : ''}`;
        }
    }

    private renderMainColumn(activeItem: Item): JSX.Element {
        if (activeItem) {
            return (
                <div className='accordion-content-bottom'>
                    {activeItem.view === ItemViewMode.Image ?
                        <div className='accordion-content-bottom-image'>
                            <div className='accordion-content-bottom-image__toolbar' />
                            {!!activeItem?.imgBase64 ? <div className='accordion-content-bottom-image__img'
                                style={{ backgroundImage: `url(${activeItem.imgBase64})` }} /> : <div />}
                        </div> :
                        <div className='accordion-content-bottom-pdf'>
                            {activeItem?.pdfBase64?.length > 50 ?
                                <PdfViewer src={activeItem.pdfBase64} /> : <div />}
                        </div>
                    }
                </div>
            )
        }
        return <div />
    }

    private changeView(_item: Item, view: ItemViewMode) {
        const items: Array<Item> = this.state.items;
        const item: Item = items.filter(i => i.id === _item.id)[0] as Item;
        item.view = view;

        this.setState({ items });
    }

    private onCloseLabelClick() {
        this.setState({ isOpened: false });
    }
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            [PS_PRODUCT_ACCORDION]: any
        }
    }
}